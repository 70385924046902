import React from 'react';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import NavRouter from './navigation/NavRouter'
import * as _ from 'lodash';
import { InitializeApp } from './utils/initializeApp'
import { CLEAR_STORE } from './actions/types';

import 'react-toastify/dist/ReactToastify.css'
import './styles.css'

const onBeforeLift = (store) => {
  // PersistGate delays the rendering of your app's UI until your persisted state has been retrieved and saved to redux store
  // Use this callback to take some action before the gate lifts
  console.log('After this persistGate will lift...')

  let isNewStore = !_.get(store.getState(), 'user.storeVersion1', false)
  console.log('IS_NEW_STORE: ', isNewStore)
  if (isNewStore) {
    // Clear all reducers
    store.dispatch({ type: CLEAR_STORE })
  }
}

const { store, persistor } = InitializeApp()

function App() {
  return (
    <Provider store={store}>
      <PersistGate
        persistor={persistor}
        loading={null}
        onBeforeLift={() => onBeforeLift(store)}
        debug>
          <NavRouter />
      </PersistGate>
    </Provider >
  );
}

export default App;
