import axios from 'axios';
import moment from "moment";
import { configs, BUSINESS_TYPES, DEFAULT_COMMON_SHOP_CONFIGURATIONS } from '../utils/configs';
import * as _ from 'lodash';

const baseURL = configs.baseURL

export async function login(email, password) {

	const loginData = {
		password: password,
		portal: "Client",
		username: email
	}

	const res = await axios.post(`${baseURL}/user/login`, loginData)
	return res
}

export async function signUp(shopData) {
	const res = await axios.post(`${baseURL}/shop/add`, shopData)
	return res
}

// Get shopdata from slug
export const fetchShopData = async (businessId) => {
	return new Promise(async (resolve, reject) => {

		try {
			let res = await axios.get(`${baseURL}/shop/get/shop_list_with_branches?businessId=${businessId}`)
			let isSubscribed = res.data.data.shopListWithBranches[0]?.subscribed
			if (isSubscribed) {
				resolve(res)
			} else {
				reject({ message: "Temporarily unavailable, Try again later" })
			}

		} catch (error) {
			reject({ message: "Fail to load shop data" })
		}

	})
}

// Get servics
export async function fetchServices(branchId, shopId, gender = '') {
	let URL = `${baseURL}/service/get/service_list_with_employee_or_facility?branchId=${branchId}&shopId=${shopId}`;

	if (gender) {
		URL = URL + `&gender=${gender}`
	}

	const res = await axios.get(URL)
	return res
}

export async function fetchServicesAdvanced(configs) {
	const {branchId, shopId, isEmpAndFacEnabled, pageNumber, pageSize, gender} = configs;
	let URL = `${baseURL}/service/get/all/public?branchId=${branchId}&shopId=${shopId}&isEmpAndFacEnabled=${isEmpAndFacEnabled}`;

	if (gender) {
		URL += `&gender=${gender}`
	}

	if (_.isInteger(pageNumber) && _.isInteger(pageSize)) {
		URL += `&page=${pageNumber}&size=${pageSize}`;
	}

	const res = await axios.get(URL)
	return res
}

export async function fetchEmployeeListFromServiceAndGender(serviceId, gender) {
	let URL = `${baseURL}/employee/get/employee_list_from_service_and_gender?serviceId=${serviceId}`;

	if (gender) {
		URL = URL + `&gender=${gender}`
	}

	const res = await axios.get(URL)
	return res
}

export async function fetchFacilityListFromService(serviceId) {
	let URL = `${baseURL}/facility/get/list?serviceId=${serviceId}`;

	const res = await axios.get(URL)
	return res
}

export async function fetchAppointmentsFromEmployee(empId, todayDate) {

	const res = await axios.get(`${baseURL}/appointment/get/list_from_employee?employeeId=${empId}&todayDate=${todayDate}`)
	return res
}

export async function fetchShopHolidays(shopId, branchId) {

	const res = await axios.get(`${baseURL}/shop_holiday/get/list?shopId=${shopId}&branchId=${branchId}`)
	return res
}

export async function fetchAppointmentsFromFacility(facilityId, todayDate) {

	const res = await axios.get(`${baseURL}/appointment/get/list_from_facility?facilityId=${facilityId}&todayDate=${todayDate}`)
	return res
}

export async function fetchAppoinmentsForAllFacilities(serviceId, date) {
	const res = await axios.get(`${baseURL}/appointment/get/list_from_service?serviceId=${serviceId}&Date=${date}`)
	return res
}

// Appointments

export async function addAppointment(appointment, callback) {
	const configs = {
		headers: {
			Authorization: window.localStorage.getItem('ClientLoginToken'),
		}
	}

	try {
		let addAppointmentResponse = await axios.post(`${baseURL}/appointment/add`, appointment, configs)
		callback(addAppointmentResponse)
	}
	catch (error) {
		console.log(error)
		callback(false)
	}
}

export async function confirmAppointment(appointment, callback) {
	const configs = {
		headers: {
			Authorization: window.localStorage.getItem('ClientLoginToken'),
		}
	}

	try {
		let confirmAppointmentResponse = await axios.put(`${baseURL}/appointment/confirm`, appointment, configs)
		callback(confirmAppointmentResponse)
	}
	catch (error) {
		console.log(error)
		callback(false)
	}
}

export async function getPaymentInfo(bulkAppointmentId) {

	const configs = {
		headers: {
			Authorization: window.localStorage.getItem('ClientLoginToken'),
		}
	}

	const res = await axios.get(`${baseURL}/hnb_ipg/get_payment_details?bulkAppointmentId=${bulkAppointmentId}`, configs)
	return res
}

// Appointments

// Load appointments
export async function getMyAppointments(customerId) {
	const configs = {
		headers: {
			Authorization: window.localStorage.getItem('ClientLoginToken'),
		}
	}
	let future = []
	let past = []
	let cancelled = []

	try {
		const response = await axios.get(`${baseURL}/appointment/get/list_from_customer?customerId=${customerId}`, configs)
		let appointments = response.data.data.appointmentList
		console.log("appointments", appointments)



		appointments.map(appointment => {
			let curerntTime = new Date()
			let startTime = `${moment(appointment.appointmentDate).format('YYYY/MM/DD')} ${appointment.startTime}`
			let endTime = `${moment(appointment.appointmentDate).format('YYYY/MM/DD')} ${appointment.endTime}`
			startTime = new Date(startTime)
			endTime = new Date(endTime)
			appointment.startTime = startTime
			appointment.endTime = endTime
			// appointmentTime.setHours(appointment.startTime.split(':')[0])
			// appointmentTime.setMinutes(appointment.startTime.split(':')[1])
			// appointmentTime.setSeconds(0)
			if (appointment.status === "Active") {
				if (curerntTime > startTime) {
					past.push(appointment)
				}
				else {
					future.push(appointment)
				}

			} else {
				console.log("Cancelled", appointment);
				cancelled.push(appointment)
			}
		})
		console.log({ future, past, cancelled })
		return { future, past, cancelled }
	}
	catch (error) {
		console.log(error)
		return { future: [], past: [], cancelled: [] }
	}

}

export async function cancelAppointment(apointmentId) {
	const configs = {
		headers: {
			Authorization: window.localStorage.getItem('ClientLoginToken'),
		}
	}

	const res = await axios.put(`${baseURL}/appointment/cancel`, apointmentId, configs)
	return res

}

export async function cancelAppointmentBeforeOnlinePayment(referenceNumberObject) {
	const configs = {
		headers: {
			Authorization: window.localStorage.getItem('ClientLoginToken'),
		}
	}

	const res = await axios.put(`${baseURL}/appointment/cancel/after_canceling_payment`, referenceNumberObject, configs)
	return res

}

export async function getCustomerInfo(contactNo) {

	const configs = {
		headers: {
			Authorization: window.localStorage.getItem('ClientLoginToken'),
		}
	}

	const res = await axios.get(`${baseURL}/customer/search?contactNo=${contactNo}`, configs)
	return res
}

export async function fetchPaymentPackageList() {

	try {
		const res = await axios.get(`${baseURL}/utility/package/list/customer_portal?portalType=Customer`)
		return res
	} catch (error) {
		console.log(error)
	}
}

export async function onPayAppointments(appointments, callback) {
	const configs = {
		headers: {
			Authorization: window.localStorage.getItem('ClientLoginToken'),
		}
	}

	try {
		let addAppointmentResponse = await axios.post(`${baseURL}/hnb_ipg/add_appointment_and_get_payment_details`, appointments, configs)
		callback(addAppointmentResponse)
	}
	catch (error) {
		console.log(error)
		callback(false)
	}
}

// Get shop configurations from slug
export const fetchShopConfiguration = async (shopId, businessType) => {
	return new Promise(async (resolve, reject) => {

		try {
			const shopConfigurations = {..._.get(BUSINESS_TYPES[businessType], 'shop_configurations', DEFAULT_COMMON_SHOP_CONFIGURATIONS)};
			const res = await axios.get(`${baseURL}/shop_configuration/view?shopId=${shopId}`)

			if (res && res.data && res.data.data && Array.isArray(res.data.data.shopConfigurationDTORsList)) {
				res.data.data.shopConfigurationDTORsList.forEach((config) => {
					let configValue = config.configValue;

					if (config.dataType === 'Boolean') {
						configValue = configValue === 'true';
					}
					shopConfigurations[config.configCode] = configValue
				})
			}

			resolve(shopConfigurations)
		} catch (error) {
			reject({ message: "Fail to load shop data" })
		}

	})
}
