import {
    CHANGE_AUTH_STATE,
    CLEAR_STORE,
    LOGIN,
    SIGNUP,
    GET_PAYMENT_PACKAGES_SUCCESS,
    ADMIN_EMAIL_LOGIN_TRY,
} from "../actions/types";

const INITIAL_STATE = {
    storeVersion1: true,
    isLoggedIn: false,
    fromSignUp: false,
    uid: null,
    login: [],
    contactNo: '',
    customerFirstName: '',
    imageUrl: '',
    customerId: null,
    customerLastName: '',
    email: '',
    status: '',
    paymentPackageList:[],
    isAdminEmailLoginTry: false,

    isUpdatingUser: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_STORE:
            return {
                ...INITIAL_STATE
            }
        case LOGIN:
            return {
                ...state,
                login: action.payload.user
            }
        case CHANGE_AUTH_STATE:
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn
            }
        case SIGNUP:
            return {
                ...state,
                ...action.payload.userProfile
            }
        case GET_PAYMENT_PACKAGES_SUCCESS:
            return {
                ...state,
                paymentPackageList: action.payload.paymentPackageList
            }
        case ADMIN_EMAIL_LOGIN_TRY:
            return {
                ...state,
                isAdminEmailLoginTry: action.payload.isAdminEmailLoginTry
            }
        default:
            return state
    }
}
