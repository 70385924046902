import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import ReduxThunk from 'redux-thunk'
import rootReducer from '../reducers'


export const InitializeApp = () => {

  /* ------------- Redux Configuration ------------- */
  const middleware = []
  const enhancers = []

  /* ------------- ReduxThunk Middleware ------------- */
  middleware.push(ReduxThunk)
  /* ------------- Redux Logger Middleware ------------- */
  // Note: logger must be the last middleware in chain, otherwise it will log
  // thunk and promise, not actual actions.

  function noop() { }

  if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require('redux-logger')
    const logger = createLogger({ collapsed: true, duration: true, diff: true })
    middleware.push(logger)
  } else {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
    console.info = noop;
  }

  /* ------------- Assemble Middleware ------------- */
  const middlewareEnhancer = applyMiddleware(...middleware)
  enhancers.push(middlewareEnhancer)

  const persistConfig = {
    key: 'REDUX_OFFLINE_STORE',
    storage,
    whitelist: ['user']
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  let store = createStore(persistedReducer, {}, compose(...enhancers))

  if (process.env.NODE_ENV === 'development') {
    const devToolEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(persistedReducer, {}, devToolEnhancers(
      ...enhancers
    ));
  }

  const persistor = persistStore(store)

  return { store, persistor }
}
