import {
    LOGIN,
    CHANGE_AUTH_STATE,
    SIGNUP,
    GET_PAYMENT_PACKAGES_SUCCESS,
    ADMIN_EMAIL_LOGIN_TRY
} from '../actions/types'
import { fetchPaymentPackageList } from '../functions/api'
import _ from "lodash"

export const signUp = (user, fromSignUp, isLoggedIn) => {
    return (dispatch) => {
        console.log("SignUp Data", user)
        let userProfile = user
        dispatch({ type: SIGNUP, payload: {userProfile}})
        dispatch({ type: CHANGE_AUTH_STATE, payload: { isLoggedIn } })
    }
}

export const getLoginData = (user, isLoggedIn ) => {
    return async (dispatch) => {
        if (user) {
            dispatch({ type: LOGIN, payload: { user } })
            dispatch({ type: CHANGE_AUTH_STATE, payload: { isLoggedIn } })
        }
    }
}

export const changeAuthState = (isLoggedIn) => {
    return (dispatch) => {
            console.log("isLoggedIn", isLoggedIn)
            dispatch({ type: CHANGE_AUTH_STATE, payload: { isLoggedIn } })
    }
}

export const getPaymentPackageList = () => {
    return async (dispatch) => {
        try {
            let res = await fetchPaymentPackageList()
            let paymentPackageList = res.data.data.paymentPackageInfoList
            dispatch({ type: GET_PAYMENT_PACKAGES_SUCCESS, payload: { paymentPackageList } })
        } catch (error) {
            console.log(_.get(error, 'response.data.message'));
        }
    }
}

export const adminEmailLoginTry = (isAdminEmailLoginTry) => {
    return (dispatch) => {
            console.log("isAdminEmailLoginTry", isAdminEmailLoginTry)
            dispatch({ type: ADMIN_EMAIL_LOGIN_TRY, payload: { isAdminEmailLoginTry } })
    }
}
